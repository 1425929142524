/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.event-calendar {
  background: #FFFFFF;
  overflow: hidden;
  box-sizing: border-box;
  padding: 5px;
  border-bottom: none !important;
}
